<template>
  <div v-if="isAuthenticated" class="d-flex flex-column flex-root">
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile />
    <!-- end:: Header Mobile -->

    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo" />

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page">
      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <!-- begin:: Header -->
        <KTHeader :header-menu-show="false" />
        <!-- end:: Header -->

        <!-- begin:: Content -->
        <div id="kt_content" class="content d-flex flex-column flex-column-fluid">
          <!-- begin:: Content Head -->

          <!-- begin:: Content Head -->
          <KTSubheader v-if="subheaderDisplay" v-bind:breadcrumbs="breadcrumbs" v-bind:title="pageTitle" />
          <!-- end:: Content Head -->

          <!-- begin:: Content Body -->
          <div class="d-flex flex-column-fluid">
            <div :class="{ 'container-fluid': contentFluid, container: !contentFluid }">
              <template v-if="!loading">
                <details-header
                  :applicant="user"
                  :seller="seller"
                  :application="application"
                  :cooperative="cooperative"
                  :loanRatio="getLoanRatio(application)"
                  :debtIncomeRatio="getDebtIncomeRatio(application)"
                />
                <transition name="fade-in">
                  <router-view />
                </transition>
              </template>
              <template v-else>
                <div class="position-relative" style="height:400px;">
                  <spinner bg-transparent />
                </div>
              </template>
            </div>
          </div>
        </div>
        <KTFooter></KTFooter>
      </div>
    </div>
    <KTScrollTop></KTScrollTop>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import KTAside from '@/components/blocks/Aside';
import KTHeader from '@/components/blocks/Header';
import KTHeaderMobile from '@/components/blocks/HeaderMobile';
import KTFooter from '@/components/blocks/Footer';
import HtmlClass from '@/helpers/htmlclass.service';
import KTSubheader from '@/components/blocks/Subheader';
import KTScrollTop from '@/components/generic/ScrollTop';
import Loader from '@/components/generic/Loader';
import DetailsHeader from '@/components/application/DetailsHeader';
import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME } from '@/store/modules/htmlclass.js';
import applicationLoans from '@/mixins/application-loans';
export default {
  name: 'ApplicationsSingleLayout',
  mixins: [applicationLoans],
  components: {
    KTHeader,
    KTHeaderMobile,
    KTFooter,
    KTSubheader,
    KTScrollTop,
    Loader,
    DetailsHeader,
  },
  data() {
    return {
      loading: true,
      avatar: null,
    };
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, 'page-loading');

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  async mounted() {
    this.loading = true;
    // check if current user is authenticated
    try {
      await this.getApplication(this.applicationId);
      await this.fetchContacts();
    } catch (error) {
      console.error('error :>> ', error);
    }
    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, 'page-loading');
    }, 1000);

    if (this.application.step_status < 6) this.$router.push({ name: 'applications-create' });

    this.loading = false;
  },
  methods: {
    ...mapActions({
      getApplication: 'applications/show',
      getContacts: 'contacts/index',
    }),

    async fetchContacts() {
      const params = {
        elasticsearch: true,
        per: Math.pow(10, 12),
        where: {
          application_id: this.applicationId,
        },
      };
      await this.getContacts(params);
    },
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      breadcrumbs: 'breadcrumbs/breadcrumbs',
      pageTitle: 'breadcrumbs/pageTitle',
      layoutConfig: 'config/layoutConfig',
      application: 'applications/item',
      user: 'auth/user',
      contacts: 'contacts/items',
    }),
    seller() {
      const data = this.contacts.filter((c) => c.attributes.contact_type === 'Seller');
      return data.length > 0 ? data[data.length - 1] : {};
    },
    applicant() {
      return this.user;
    },
    cooperative() {
      const data = this.get(this.application, 'cooperative.data');
      return { ...data.attributes, id: data.id };
    },
    applicationId() {
      return this.get(this.user, 'application.data.id', null);
    },
    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig('content.width') === 'fluid';
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig('aside.self.display');
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return true;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig('subheader.display');
    },
  },
};
</script>
