<template>
  <div>
    <b-card class="card-custom gutter-b">
      <b-row>
        <b-col md="6" lg="">
          <div class="d-md-flex">
            <div class="flex-shrink-0 mb-5 mb-md-0 mr-md-7">
              <cs-avatar
                classes="symbol symbol-50 symbol-lg-100 font-size-h3 symbol-label font-weight-boldest"
                :src="get(applicant, 'avatar')"
                :name="get(applicant, 'first_name') + get(applicant, 'last_name')"
                :initial-letters="2"
                divided=" "
              ></cs-avatar>
            </div>
            <div class="flex-grow-1">
              <div class="mt-2">
                <div class="text-dark font-size-h5 font-weight-bold">
                  Applicant Details
                </div>
                <div class="d-flex flex-wrap my-2">
                  <a href="#" class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                    <span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                      <inline-svg src="media/svg/icons/Home/Building.svg" />
                    </span>
                    {{ get(cooperative, 'company_name') }}
                  </a>
                </div>
              </div>
              <div class="font-weight-bold text-dark-50 py-2 py-lg-2">
                {{ get(applicant, 'street_address') }}
                <br />
                {{ get(applicant, 'city') }}
                <br />
                {{ get(applicant, 'state') }} {{ get(applicant, 'zip') }}
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="6" lg="">
          <div class="mt-2">
            <div class="text-dark font-size-h5 font-weight-bold">
              Transactional Info
            </div>
          </div>
          <div class="font-weight-bold text-dark-50 py-2 py-lg-2">
            <ul class="list-unstyled mb-0">
              <li class="d-flex justify-content-between mb-1">
                Apartment Number:
                <span class="font-weight-bolder text-dark">{{ get(application, 'appartment_number') }}</span>
              </li>
              <li class="d-flex justify-content-between mb-1">
                Purchase Price:
                <span class="font-weight-bolder text-dark">
                  {{ moneyDollarForamter(get(application, 'purchase_price')) }}
                </span>
              </li>
              <li class="d-flex justify-content-between mb-1">
                Sellers Name:
                <span class="font-weight-bolder text-dark">{{
                  get(seller, 'attributes.first_name') + ' ' + get(seller, 'attributes.last_name')
                }}</span>
              </li>
              <li class="d-flex justify-content-between mb-0">
                Number of Shares:
                <span class="font-weight-bolder text-dark">{{ get(application, 'number_of_shares_of_stock') }}</span>
              </li>
            </ul>
          </div>
        </b-col>
        <b-col md="6" lg="">
          <div class="mt-2">
            <div class="text-dark font-size-h5 font-weight-bold">
              Applicants Credit
            </div>
          </div>
          <div class="font-weight-bold text-dark-50 py-2 py-lg-2">
            <ul class="list-unstyled mb-0">
              <li class="d-flex justify-content-between mb-1">
                Loan to Value Ratio:
                <span
                  class="font-weight-bolder"
                  :class="[loanRatio > get(cooperative, 'maximum_loan_ratio', 0) ? 'text-danger' : 'text-success']"
                >
                  {{ loanRatio != '-' ? loanRatio + '%' : '' }}
                </span>
              </li>

              <li class="d-flex justify-content-between mb-1">
                Debt to Income Ratio:
                <span
                  class="font-weight-bolder"
                  :class="[
                    debtIncomeRatio > get(cooperative, 'maximum_debt_ratio', 0) ? 'text-danger' : 'text-success',
                  ]"
                >
                  {{ debtIncomeRatio != '-' ? debtIncomeRatio + '%' : '' }}
                </span>
              </li>
              <li class="d-flex justify-content-between mb-1">
                Credit Score:
                <span class="font-weight-bolder text-danger">{{ application.min_credit_score || '-' }}</span>
              </li>
              <li class="d-flex justify-content-between mb-0">
                Amount of loan:
                <span class="font-weight-bolder text-dark">
                  {{ moneyDollarForamter(get(application, 'purchase_price_loan_amount')) }}
                </span>
              </li>
              <!-- <li class="d-flex justify-content-between mb-0">
                Financing:
                <span class="font-weight-bolder text-dark">{{ application.require ? 'Mortgage' : 'Cash' }}</span>
              </li> -->
            </ul>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
export default {
  props: {
    seller: {
      type: [Object],
      default: () => {},
    },
    applicant: {
      type: [Object],
      default: () => {},
    },
    application: {
      type: [Object],
      default: () => {},
    },
    cooperative: {
      type: [Object],
      default: () => {},
    },
    loanRatio: {
      type: [Number, String],
      default: '-',
    },
    debtIncomeRatio: {
      type: [Number, String],
      default: '-',
    },
  },
};
</script>

<style lang="scss" scoped></style>
